<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          <v-flex xs2>
            <v-menu
              ref="menu1"
              v-model="beginDateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formattedMonth"
                  :label="$t('global.month')"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" type="month" color="primary" @input="beginDateMenu = false"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-spacer />
          <id-button class="mr-2" @click="onCreateLeaveRequest">
            <template #icon>
              <v-icon left>mdi-plus</v-icon>
            </template>
            {{ $t("buttons.add_leave_request") }}
          </id-button>
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>

        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          class="elevation-1"
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
        >
          <template slot="item" slot-scope="row">
            <tr :key="row.item.id">
              <td class="pl-0">
                <v-list-item>
                  <v-list-item-avatar :color="row.item.leaveType.color">
                    <v-icon dark>{{ row.item.leaveType.icon }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title> {{ row.item.leaveType.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      $t(`leave_duration.${row.item.beginLeaveRequestDay.duration}`, [])
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </td>
              <td class="text-xs-left text-no-wrap">
                {{ $helpers.formatDate(row.item.beginLeaveRequestDay.date) }}
              </td>
              <td class="text-xs-left text-no-wrap">
                {{ $helpers.formatDate(row.item.endLeaveRequestDay.date) }}
              </td>

              <td class="text-xs-left text-no-wrap">
                <v-chip label color="white">
                  <v-avatar left :color="getLeaveStatusIconColor(row.item.leaveRequestStatus)">
                    <v-icon size="18" color="white">{{ getLeaveStatusIcon(row.item.leaveRequestStatus) }}</v-icon>
                  </v-avatar>
                  {{ getLeaveStatusIconText(row.item.leaveRequestStatus) }}
                </v-chip>
              </td>
              <td class="text-xs-left text-no-wrap">
                {{ row.item.requestDateTime | formattedDate }}
              </td>
              <td class="text-xs-left text-no-wrap">
                {{ row.item.approvedDateTime | formattedDate }}
              </td>
              <td class="text-center text-no-wrap">
                <v-chip
                  v-if="row.item.signatureStatus === 'E_SIGNATURE'"
                  small
                  class="caption white--text"
                  :color="row.item.status ? 'info' : 'warning'"
                >
                  {{ row.item.status ? $t("leave.signed") : $t("leave.not_signed") }}
                </v-chip>
                <span v-else>-</span>
              </td>
              <td class="text-center" @click.stop.prevent="">
                <file-download-button
                  :url="row.item.leaveRequestFormURL"
                  v-if="row.item.leaveRequestFormURL && row.item.leaveRequestStatus === 'APPROVED'"
                />
              </td>
              <td class="text-center">
                <print-leave-form-button :leave-request-id="row.item.id" />
              </td>
              <td>
                <v-btn
                  v-if="
                    row.item.leaveRequestStatus !== 'CANCELLED' &&
                      row.item.leaveRequestStatus !== 'DENIED' &&
                      row.item.leaveRequestStatus !== 'APPROVED'
                  "
                  small
                  dark
                  depressed
                  color="primary"
                  @click.stop="onClickLeaveCancel(row.item)"
                  class="mr-2"
                >
                  {{ $t("buttons.cancel_leave") }}
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <transition name="slide-in-left">
          <router-view @success:save="onRefreshData" />
        </transition>
      </v-card>
    </v-col>
    <leave-cancel-dialog
      v-model="cancelDialog.status"
      :data.sync="cancelDialog.data"
      @close="onCloseLeaveDialog"
      @success="onSuccessLeaveCancel"
    />
  </v-row>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description shifts Component Logic
   * @date 25.07.2020
   */
  import { LEAVE_REQUESTS } from "../query";

  export default {
    name: "LeaveRequest",
    components: {
      PrintLeaveFormButton: () => import("./components/print-leave-form-button"),
      "leave-cancel-dialog": () => import("./components/leave-cancel-dialog")
    },
    data: vm => ({
      cancelDialog: {
        status: false,
        data: null
      },
      beginDateMenu: false,
      date: vm
        .$moment()
        .startOf("month")
        .format("YYYY-MM"),
      table: {
        loading: false,
        data: [],
        criteria: {
          employees: [],
          beginDate: vm
            .$moment()
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: vm
            .$moment()
            .endOf("month")
            .format("YYYY-MM-DD")
        },
        options: {},
        pagination: {
          rowsPerPage: 50,
          sortBy: "requestDateTime",
          descending: true
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("leave.type"),
            align: "left",
            sortable: false,
            value: "leaveType.name",
            class: ["primary--text text-lg-left"]
          },
          {
            text: vm.$t("leave.begin_date"),
            align: "left",
            sortable: false,
            value: "beginLeaveRequestDay.date",
            class: ["primary--text text-lg-left"]
          },
          {
            text: vm.$t("leave.end_date"),
            align: "left",
            sortable: false,
            value: "endLeaveRequestDay.date",
            class: ["primary--text text-lg-left"]
          },

          {
            text: vm.$t("leave.status"),
            align: "left",
            sortable: false,
            value: "leaveRequestStatus",
            class: ["primary--text text-lg-left"]
          },
          {
            text: vm.$t("leave.request_time"),
            align: "left",
            sortable: false,
            value: "requestDateTime",
            class: ["primary--text text-lg-left"]
          },
          {
            text: vm.$t("leave.approved_time"),
            align: "left",
            sortable: false,
            value: "approvedDateTime",
            class: ["primary--text text-lg-left"]
          },
          {
            text: vm.$t("leave.e_signature"),
            align: "center",
            sortable: false,
            value: "leaveRequestStatus",
            class: ["primary--text"]
          },
          {
            width: "1%",
            text: vm.$t("leave.e_signature_leave_form"),
            align: "center",
            sortable: false,
            class: ["primary--text", "text-no-wrap"]
          },
          {
            text: vm.$t("leave.leave_form"),
            width: "1%",
            align: "center",
            sortable: false,
            class: ["primary--text", "text-no-wrap"]
          },
          {
            text: vm.$t("leave.leave_cancel"),
            width: "1%",
            align: "center",
            sortable: false,
            class: ["primary--text", "text-no-wrap"]
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.table.criteria.employees = [this.$store.state.auth.user.id];

          this.fetchItems();
        },
        deep: true
      },
      date: {
        handler(v) {
          this.table.criteria.beginDate = this.$moment(v)
            .startOf("month")
            .format("YYYY-MM-DD");
          this.table.criteria.endDate = this.$moment(v)
            .endOf("month")
            .format("YYYY-MM-DD");
        },
        deep: true
      },
      "table.criteria": {
        handler() {
          this.fetchItems();
        },
        deep: true
      }
    },
    computed: {
      formattedMonth() {
        return this.$moment(this.date).format("MMMM");
      }
    },
    methods: {
      onClickLeaveCancel(leave) {
        this.cancelDialog.data = leave;
        this.cancelDialog.status = true;
      },
      onCloseLeaveDialog() {
        this.cancelDialog.data = null;
        this.cancelDialog.status = false;
      },
      onSuccessLeaveCancel() {
        this.onCloseLeaveDialog();
        this.onRefreshData();
      },
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: LEAVE_REQUESTS,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.convertPagination(this.table.pagination)
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.table.data = data.filterWithPaginateLeaveRequests.content || [];
              this.table.totalItems = data.filterWithPaginateLeaveRequests.pageInfo.totalElements || 0;
              this.table.totalPages = data.filterWithPaginateLeaveRequests.pageInfo.totalPages || 0;
            }
          })
          .catch(e => {
            console.log("e", e);
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      },
      getLeaveStatusIconText(leaveStatus) {
        return this.$t(`leave_request_status.${leaveStatus.toLowerCase()}`);
      },
      getLeaveStatusIcon(leaveStatus) {
        let icon = "";
        switch (leaveStatus) {
          case "PENDING":
            icon = "mdi-account-clock";
            break;
          case "APPROVED":
            icon = "mdi-account-multiple-check";
            break;
          case "CANCELLED":
            icon = "mdi-account-remove";
            break;
          case "NEED_EXTRA_DETAIL":
            icon = "mdi-account-details";
            break;
          case "DENIED":
            icon = "mdi-account-cancel";
            break;
          default:
            icon = "mdi-account-question";
            break;
        }
        return icon;
      },
      getLeaveStatusIconColor(status) {
        let color = "";
        switch (status) {
          case "PENDING":
            color = "orange";
            break;
          case "APPROVED":
            color = "green";
            break;
          case "CANCELLED":
            color = "grey";
            break;
          case "NEED_EXTRA_DETAIL":
            color = "blue";
            break;
          case "DENIED":
            color = "red";
            break;
          default:
            color = "black";
            break;
        }
        return color;
      },
      onCreateLeaveRequest() {
        this.$router.push({ name: "create_leave_request" });
      },
      getTotalLeaveDay(leave) {
        if (leave.beginLeaveRequestDay.duration === "HOURLY") {
          return "Saatlik";
        } else {
          return this.$t(`leave_duration.${leave.beginLeaveRequestDay.duration}`, []);
        }
      }
    }
  };
</script>
